<template>
	<div id="content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="box">
						<div class="row">
							<label class="col col-form-label" for="horse_cats">{{ $t('horse.cat_cheval')}}</label>
							<label class="col col-form-label" for="residence">{{ $t('global.residences')}}</label>
							<label class="col col-form-label" for="pension_id">{{ $t('global.pension')}}</label>
						</div>
						<div class="row">
							<div class="col">
								<e-select
									id="horse_cats"
									class="mb-3"
									v-model="categorie_selected"
									track-by="categorie_id"
									label="categorie_libelle"
									:placeholder="$t('horse.categorie_cheval')"
									:options="all_categorie"
									:searchable="true"
									:allow-empty="true"
									:loading="load_categorie"
									:multiple="true"
									:show-labels="false"
								>
									<template slot="noOptions">{{ $t('categorie.aucune_categorie') }}</template>
								</e-select>
							</div>
							<div class="col">
								<e-select
									id="residence"
									v-model="residence_selected"
									:options="residences"
									:searchable="true"
									label="lieu_label"
									:placeholder="$t('monte.choix_lieu')"
									:loading="laoding_residence"
								>	
									<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
							<div class="col">
								<e-select
									v-model="pension_selected"
									id="pension_id"
									track-by="pension_id"
									label="pension_label"
									:placeholder="$t('acte.selectionnez_pension')"
									:selectedLabel="$t('global.selected_label')"
									:options="all_pensions"
									:internal-search="false"
									:searchable="true"
									:allow-empty="true"
									:loading="loading_pension"
									:show-labels="false"
									:multiple="true"

									group-values="pensions"
									group-label="accountingplan_label"
									:group-select="false"
								>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<DateRange
									:start.sync="start_date"
									:end.sync="end_date"
									@submit="loadHorseWithoutPension"
								/>
							</div>
						</div>
					</div>
					<div class="box">
						<div class="row">
							<div class="col-12">
								<LoadingSpinner v-if="loading" class="col-12" />

								<CustomTable
									v-else
									id_table="horse_without_pension"
									primaryKey="horse_id"
									:hrefsRoutes="config_table_hrefs"
									:items="horses"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Horse from "@/mixins/Horse.js"
    import Lieux from "@/mixins/Lieux.js"
    import Actes from "@/mixins/Actes.js"
    import Navigation from '@/mixins/Navigation.js'
	import Accounting from "@/mixins/Accounting.js"


	export default {
		name: "HorseWithoutAct",
		mixins: [Horse, Lieux, Actes, Navigation, Accounting],
		data () {
			return {
				categorie_selected: [],
            	all_categorie: [],
            	load_categorie: true,
            	residence_selected: null,
            	residences: [],
            	laoding_residence: true,
            	pension_selected: [],
            	all_pensions: [],
            	loading_pension: true,
            	start_date: new Date(new Date().setDate(new Date().getDate() - 90)),
				end_date: new Date(),
				horses: [],
				loading: false,
                config_table_hrefs: {
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
                },
				events_tab: {
					'TableAction::goToSelectHorses': this.onSelectHorses
				}
			}
		}, 
		mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
            	this.load_categorie = true
				this.all_categorie = await this.getAllCategorie()
				this.load_categorie = false

				this.laoding_residence = true
				this.residences = await this.getResidences()
				this.laoding_residence = false

				this.loading_pension = true
				let accounting = await this.loadAccountingPlans()
				for (const account of accounting) {
					account.pensions = await this.loadPensionType(account.accountingplan_id)
				}
				this.all_pensions = accounting
				this.loading_pension = false
			},

			async loadHorseWithoutPension() {
				this.loading = true
				const pensions_ids = this.pension_selected.map(pens => pens.pension_id)
				const categories_ids = this.categorie_selected.map(cat => cat.categorie_id)
				this.horses = await this.getHorseWithoutPensions(categories_ids, this.residence_selected, pensions_ids, this.start_date, this.end_date)
				this.loading = false
			}
		},
		components: {
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
